// import regular icons
import { faGoogle as bGoogle } from '@fortawesome/free-brands-svg-icons';
// import light icons
import {
  faCancel as lCancel,
  faCircle as lCircle,
  faCircleCheck as lCircleCheck,
  faClose as lClose,
  faPrint as lPrint,
  faTimesCircle as lTimesCircle,
} from '@fortawesome/pro-light-svg-icons';
import {
  faAddressBook as rAddressBook,
  faAddressCard as rAddressCard,
  faArrowCircleRight as rArrowCircleRight,
  faArrowRight as rArrowRight,
  faArrowsLeftRight as rArrowsLeftRight,
  faArrowsUpDown as rArrowsUpDown,
  faArrowsUpDownLeftRight as rArrowsUpDownLeftRight,
  faArrowUpRightAndArrowDownLeftFromCenter as rArrowUpRightAndArrowDownLeftFromCenter,
  faBarcodeRead as rBarcodeRead,
  faBolt as rBolt,
  faBox as rBox,
  faBoxCheck as rBoxCheck,
  faBoxesPacking as rBoxesPacking,
  faBoxesStacked as rBoxesStacked,
  faBoxHeart as rBoxHeart,
  faBuilding as rBuilding,
  faCalendar as rCalendar,
  faCalendarClock as rCalendarClock,
  faCancel as rCancel,
  faCheck as rCheck,
  faChevronDown as rChevronDown,
  faCircleLocationArrow as rCircleLocationArrow,
  faCirclePlus as rCirclePlus,
  faCircleXmark as rCircleXmark,
  faClock as rClock,
  faClone as rClone,
  faClose as rClose,
  faCompass as rCompass,
  faCopy as sCopy,
  faCross as rCross,
  faCube as rCube,
  faDownload as rDownload,
  faEllipsis as rEllipsis,
  faEllipsisVertical as rEllipsisVertical,
  faEnvelopeOpen as rEnvelopeOpen,
  faEye as rEye,
  faEyeSlash as rEyeSlash,
  faFileDownload as rFileDownload,
  faFileInvoice as rFileInvoice,
  faFilePen as rFilePen,
  faFileUpload as rFileUpload,
  faFlask as rFlask,
  faFloppyDisk as rFloppyDisk,
  faFolder as sFolder,
  faFolderTree as rFolderTree,
  faGear as rGear,
  faGlobePointer as rGlobePointer,
  faGripDotsVertical as rGripDotsVertical,
  faHome as rHome,
  faKey as rKey,
  faLocationDot as rLocationDot,
  faMagnifyingGlass as rMagnifyingGlass,
  faObjectsColumn as rObjectsColumn,
  faPen as rPen,
  faPenToSquare as rPenToSquare,
  faPlane as rPlane,
  faPlus as rPlus,
  faRectangleList as rRectangleList,
  faRightFromBracket as rRightFromBracket,
  faRocket as rRocket,
  faRuler as rRuler,
  faShieldCross as rShieldCross,
  faShip as rShip,
  faStar as rStar,
  faTally as rTally,
  faTimesCircle as rTimesCircle,
  faTrain as rTrain,
  faTrash as rTrash,
  faTriangleExclamation as rTriangleExclamation,
  faTruck as rTruck,
  faTruckClock as rTruckClock,
  faTruckFast as rTruckFast,
  faTruckRampBox as rTruckRampBox,
  faUpload as rUpload,
  faUser as rUser,
  faUserGear as rUserGear,
  faUserSecret as rUserSecret,
  faUserShield as rUserShield,
  faWarehouseFull as rWarehouseFull,
  faWebhook as rWebhook,
  faWeightScale as rWeightScale,
  faXmark as rXmark,
} from '@fortawesome/pro-regular-svg-icons';
// import solid icons
import {
  faAddressCard as sAddressCard,
  faArrowDown as sArrowDown,
  faArrowLeft as sArrowLeft,
  faArrowRight as sArrowRight,
  faArrowRotateRight as sArrowRotateRight,
  faArrowUp as sArrowUp,
  faBars as sBars,
  faBoxesPacking as sBoxesPacking,
  faBuilding as sBuilding,
  faCalendarClock as sCalendarClock,
  faCalendarXmark as sCalendarXmark,
  faCaretDown as sCaretDown,
  faCaretRight as sCaretRight,
  faCheck as sCheck,
  faCheckDouble as sCheckDouble,
  faChevronDown as sChevronDown,
  faChevronLeft as sChevronLeft,
  faChevronRight as sChevronRight,
  faChevronsDown as sChevronsDown,
  faChevronsUp as sChevronsUp,
  faCircle as sCircle,
  faCircleCheck as sCircleCheck,
  faCircleChevronRight as sCircleChevronRight,
  faCircleExclamation as sCircleExclamation,
  faCirclePlus as sCirclePlus,
  faCircleXmark as sCircleXmark,
  faDownload as sDownload,
  faEllipsis as sEllipsis,
  faEnvelope as sEnvelope,
  faEye as sEye,
  faEyeSlash as eEyeSlash,
  faFilePen as sFilePen,
  faFolderTree as sFolderTree,
  faKey as sKey,
  faList as sList,
  faLoader as sLoader,
  faLocationDot as sLocationDot,
  faLocationPen as sLocationPen,
  faMagnifyingGlass as sMagnifyingGlass,
  faMapLocationDot as sMapLocationDot,
  faMoon as sMoon,
  faPersonDolly as sPersonDolly,
  faPhoneFlip as sPhoneFlip,
  faPlug as sPlug,
  faPlus as sPlus,
  faPlusLarge as sPlusLarge,
  faRotateRight as sRotateRight,
  faServer as sServer,
  faSquareCheck as sSquareCheck,
  faStar as sStar,
  faSunBright as sSunBright,
  faTally as sTally,
  faTimes as sTimes,
  faTrash as sTrash,
  faTrashXmark as sTrashXmark,
  faTriangleExclamation as sTriangleExclamation,
  faTruck as sTruck,
  faTruckContainerEmpty as sfaTruckContainerEmpty,
  faTruckFast as sTruckFast,
  faTruckLoading as sTruckLoading,
  faTruckRampBox as sTruckRampBox,
  faUnlockKeyhole as sUnlockKeyhole,
  faUpload as sUpload,
  faUserGear as sUserGear,
  faUserSecret as sUserSecret,
  faUserShield as sUserShield,
  faWarehouseFull as sWarehouseFull,
  faWebhook as sWebhook,
} from '@fortawesome/pro-solid-svg-icons';

export const icons = [
  // regular icons
  bGoogle,
  eEyeSlash,
  rAddressBook,
  rAddressCard,
  rArrowCircleRight,
  rArrowRight,
  rArrowsLeftRight,
  rArrowsUpDown,
  rArrowsUpDownLeftRight,
  rArrowUpRightAndArrowDownLeftFromCenter,
  rBarcodeRead,
  rBolt,
  rBox,
  rBoxCheck,
  rBoxesPacking,
  rBoxesStacked,
  rBoxHeart,
  rBuilding,
  rCalendar,
  rCalendarClock,
  rCancel,
  rCheck,
  rChevronDown,
  rCircleLocationArrow,
  rCirclePlus,
  rCircleXmark,
  rClock,
  rClone,
  rClose,
  rCompass,
  rCross,
  rCube,
  rDownload,
  rEnvelopeOpen,
  rEllipsis,
  rEllipsisVertical,
  rEye,
  rEyeSlash,
  rFileDownload,
  rFileInvoice,
  rFilePen,
  rFilePen,
  rFileUpload,
  rFlask,
  rFloppyDisk,
  rFolderTree,
  rGear,
  rGear,
  rGlobePointer,
  rGripDotsVertical,
  rHome,
  rKey,
  rLocationDot,
  rObjectsColumn,
  rMagnifyingGlass,
  rPen,
  rPenToSquare,
  rPlane,
  rPlus,
  rRectangleList,
  rRightFromBracket,
  rRocket,
  rRuler,
  rShieldCross,
  rShip,
  rStar,
  rTally,
  rTimesCircle,
  rTrain,
  rTrash,
  rTriangleExclamation,
  rTruck,
  rTruckClock,
  rTruckFast,
  rTruckRampBox,
  rUser,
  rUserGear,
  rUserSecret,
  rUserShield,
  rWarehouseFull,
  rWebhook,
  rUpload,
  rWeightScale,
  rXmark,
  sTally,
  rEnvelopeOpen,
  sUserGear,
  sUserShield,

  // solid icons
  sAddressCard,
  sArrowRotateRight,
  sArrowUp,
  sArrowRight,
  sArrowDown,
  sArrowLeft,
  sBars,
  sBoxesPacking,
  sBuilding,
  sCalendarClock,
  sCalendarXmark,
  sCaretDown,
  sCaretRight,
  sCheck,
  sCheckDouble,
  sChevronDown,
  sChevronLeft,
  sChevronRight,
  sChevronsDown,
  sChevronsUp,
  sCircle,
  sCircleCheck,
  sCircleChevronRight,
  sCircleExclamation,
  sCirclePlus,
  sCircleXmark,
  sCopy,
  sDownload,
  sEnvelope,
  sEye,
  sEllipsis,
  sfaTruckContainerEmpty,
  sFilePen,
  sFolder,
  sFolderTree,
  sKey,
  sList,
  sLoader,
  sLocationDot,
  sLocationPen,
  sMagnifyingGlass,
  sMapLocationDot,
  sMoon,
  sPersonDolly,
  sPhoneFlip,
  sPlug,
  sPlus,
  sPlusLarge,
  sRotateRight,
  sServer,
  sSquareCheck,
  sStar,
  sSunBright,
  sTimes,
  sTrash,
  sTrashXmark,
  sTriangleExclamation,
  sTruck,
  sTruckFast,
  sTruckLoading,
  sTruckRampBox,
  sUnlockKeyhole,
  sUpload,
  sUserSecret,
  sWarehouseFull,
  sWebhook,

  // Light icons
  lPrint,
  lCircle,
  lCircleCheck,
  lClose,
  lCancel,
  lTimesCircle,
];
