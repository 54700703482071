<template>
  <div
    class="relative flex flex-col bg-gray-200"
    :class="showFullSize ? 'w-64 min-w-56' : 'min-w-18'"
  >
    <div id="logo" class="px-4 py-4">
      <RouterLink to="/">
        <svg
          class="h-7 w-auto fill-teal-600"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 118.95 87.74"
        >
          <path
            d="M107.59,10.18C86.2-8.31,59.73-1.83,50.74,31.42h-2.48v-5.32C48.17,11.54,35.24-.03,20.29,2.32,8.14,4.22-.73,15.62.05,27.9c.39,6.24,3,11.93,7.44,16.16,5.65,5.37,13.63,8.1,22.62,7.66,1.18-.06,2.33-.15,3.47-.28v6.75c0,12.4,7.5,23.96,19.23,27.96,3.17,1.08,6.37,1.6,9.51,1.6,9,0,17.47-4.3,22.98-12.08l31.89-45.02c2.77-3.9,2.24-9.25-1.23-12.54l-8.36-7.92s0,0,0,0ZM33.58,36.62c-1.33.21-2.73.35-4.19.42-4.78.26-9.1-1.08-11.76-3.62-1.91-1.82-2.9-4.19-2.94-7.02-.04-3.8,2.15-7.52,5.68-8.93,6.76-2.71,13.21,2.29,13.21,8.78v10.38ZM73.3,67.17c-4.3,6.07-10.79,6.64-15.43,5.17-4.64-1.48-9.61-5.71-9.61-13.14v-11.97c7.95-3.97,14.18-10.53,18.6-19.62,2.58-5.3,7.08-11.89,13.85-13.16,5.21-.98,11.37,1.39,16.82,6.46l5.11,4.84-29.35,41.43Z"
            stroke-width="0"
          />
        </svg>
      </RouterLink>
    </div>
    <div class="absolute -right-7 top-10">
      <BaseTooltip :content="showFullSize ? 'minimise' : 'expand'" side="right">
        <BaseButton
          @click="
            () => {
              (showFullSize = !showFullSize), (showConfig = false);
            }
          "
          variant="none"
        >
          <span
            class="flex items-center justify-center rounded-full border border-gray-300 bg-white p-1"
          >
            <FontAwesomeIcon
              :icon="'fa-solid ' + (showFullSize ? 'fa-chevron-left' : 'fa-chevron-right')"
              class="h-4 w-4 p-0"
            />
          </span>
        </BaseButton>
      </BaseTooltip>
    </div>
    <div id="highlighted-menu" class="border-bottom border-bottom-gray-400 px-3 pb-5 pt-10">
      <BaseTooltip content="Create shipment" side="right" :disabled="showFullSize">
        <RouterLink
          v-for="item in navTopItems"
          :key="item.link"
          :to="item.link"
          active-class="text-teal-600 bg-gray-300"
          :class="hasAccess('CREATE_SHIPMENT')"
          class="flex items-center gap-3 rounded px-3 py-3 text-gray-900 hover:bg-gray-300 hover:text-teal-600 hover:no-underline"
        >
          <FontAwesomeIcon :icon="item.icon" class="h-5 w-5" />
          <span v-if="showFullSize" class="grow font-bold">{{ item.displayName }}</span>
        </RouterLink>
      </BaseTooltip>
    </div>
    <div class="relative">
      <div class="absolute w-full">
        <div id="menu" class="flex flex-col px-3">
          <template v-if="!showConfig">
            <NavSideBarButton
              v-for="item in navItems"
              :key="item.link"
              @click="showConfig = false"
              :link="item.link"
              :icon="item.icon"
              :label="item.displayName"
              :show-label="showFullSize"
              :class="hasAccess(item.hasAccessString)"
              class="mb-2"
            />
          </template>
          <BaseTooltip
            v-if="!showConfig"
            content="Configuration"
            side="right"
            :disabled="showFullSize"
          >
            <button
              @click="
                () => {
                  (showConfig = !showConfig), (showFullSize = true);
                }
              "
              variant="text"
              activeClass="text-teal-600 bg-gray-300"
              class="flex w-full items-center gap-3 rounded px-3 py-3 text-left text-gray-900 hover:bg-gray-300 hover:text-teal-600 hover:no-underline"
              :class="hasAccess('EDIT_CONFIG')"
            >
              <FontAwesomeIcon icon="fa-regular fa-cog" class="h-5 w-5" />
              <span v-if="showFullSize" class="grow font-bold">Configuration</span>
              <FontAwesomeIcon
                v-if="!showConfig && showFullSize"
                icon="fa-regular fa-plus"
                class="h-5 w-5"
              />
            </button>
          </BaseTooltip>
        </div>
      </div>
      <div class="absolute">
        <div id="menu">
          <div v-if="showConfig" class="flex flex-row justify-start px-3 pb-4">
            <NavSideBarButton
              v-for="item in navItems"
              :key="item.link"
              @click="showConfig = false"
              :link="item.link"
              :icon="item.icon"
              :label="item.displayName"
              :show-label="!showConfig"
              :class="hasAccess('EDIT_CONFIG')"
            />
          </div>
        </div>
        <div class="px-3">
          <button
            v-if="showConfig"
            @click="showConfig = !showConfig"
            variant="text"
            activeClass="text-teal-600 bg-gray-300"
            class="flex w-full items-center gap-3 rounded px-3 py-3 text-left text-gray-900 hover:bg-gray-300 hover:text-teal-600 hover:no-underline"
          >
            <FontAwesomeIcon icon="fa-regular fa-cog" class="h-5 w-5" />
            <span class="grow font-bold">Configuration</span>
            <FontAwesomeIcon v-if="!showConfig" icon="fa-regular fa-plus" class="h-5 w-5" />
            <FontAwesomeIcon v-if="showConfig" icon="fa-regular fa-times" class="h-5 w-5" />
          </button>
          <template v-if="showConfig">
            <NavSideBarButton
              v-for="(configItem, index) in configMenu"
              :key="index"
              :link="configItem.link"
              :icon="configItem.icon"
              :label="configItem.displayName"
              :show-label="true"
              :style="{ transitionDelay: index * 30 + 'ms' }"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';

import BaseButton from '@/components/buttons/BaseButton.vue';
import BaseTooltip from '@/components/tooltip/BaseTooltip.vue';
import { AccessKey, hasAccess } from '@/composables/useHasAccess';
import { useContextStore } from '@/store';

import NavSideBarButton from './NavSideBarButton.vue';

const contextStore = useContextStore();

type NavItem = {
  icon: string;
  displayName: string;
  link: string;
  highlight?: boolean;
  hasAccessString?: keyof typeof AccessKey;
};

const showFullSize = ref(false);
const showConfig = ref(false);

const navTopItems = [
  {
    icon: 'fa-regular fa-circle-plus',
    displayName: 'Create Shipment',
    link: '/shipment/create',
    highlight: true,
  },
];
const navItems: NavItem[] = [
  {
    icon: 'fa-regular fa-boxes-stacked',
    displayName: 'All shipments',
    link: '/shipments',
    hasAccessString: 'VIEW_SHIPMENTS',
  },
  {
    icon: 'fa-regular fa-address-book',
    displayName: 'Addressbook',
    link: '/address',
    hasAccessString: 'EDIT_CONFIG',
  },
];

const configMenu = [
  {
    icon: 'fa-regular fa-warehouse-full',
    displayName: 'Shipping location',
    link: '/locations',
  },
  {
    icon: 'fa-regular fa-truck',
    displayName: 'Shipping configuration',
    link: '/configuration/shipments',
  },
  {
    icon: 'fa-regular fa-user-shield',
    displayName: 'Integration tokens',
    link: '/tokens',
  },
  { icon: 'fa-regular fa-webhook', displayName: 'Webhooks', link: '/configuration/webhooks' },
  {
    icon: 'fa-regular fa-truck-fast',
    displayName: 'Carrier configuration',
    link: `/configuration/carrier/${contextStore.getLocation?.reference}`,
  },
  {
    icon: 'fa-regular fa-user-cog',
    displayName: 'User management',
    link: '/user/management',
  },
  {
    icon: 'fa-regular fa-tally',
    displayName: 'Reference generator',
    link: '/configuration/references',
  },
  {
    icon: 'fa-regular fa-folder-tree',
    displayName: 'FTP configuration',
    link: '/configuration/ftp-configuration',
  },
];
</script>
