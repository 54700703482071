<template>
  <BaseTooltip :content="props.label" side="right" :disabled="showLabel">
    <RouterLink
      :to="props.link"
      active-class="text-teal-600 bg-gray-300"
      v-bind="$attrs"
      :class="{ 'inline-block': !showLabel }"
      class="flex items-center gap-3 rounded px-3 py-3 text-gray-900 hover:bg-gray-300 hover:text-teal-600 hover:no-underline"
    >
      <FontAwesomeIcon :icon="props.icon" class="h-5 w-5" />
      <span v-if="showLabel" class="grow font-bold">{{ props.label }}</span>
    </RouterLink>
  </BaseTooltip>
</template>
<script setup lang="ts">
import BaseTooltip from '@/components/tooltip/BaseTooltip.vue';
const props = defineProps<{
  link: string;
  icon: string;
  label: string;
  showLabel?: boolean;
}>();
</script>
