<template>
  <div class="user-context-wrapper flex" :data-testid="localTestId">
    <span
      class="current-location text-xs sm:text-sm md:text-base"
      :data-testid="localTestId + '.current-location-reference'"
    >
      {{ getLocation?.label }}
    </span>

    <div
      v-if="getLocations.length > 1"
      @click="openModal"
      class="ml-2 cursor-pointer"
      :data-testid="localTestId + '.button-trigger'"
    >
      <FontAwesomeIcon icon="fa-regular fa-location-dot" class="h-5 w-5" />
    </div>
    <DialogModal
      v-if="showModal || getLocations.length > 1"
      @close="closeModal"
      :show-modal="showModal"
      :base-test-id="localTestId"
    >
      <template #title>Change the location</template>

      <template #content="{ localTestId }">
        <div class="context-switch">
          <label class="form-label" for="location-context-switch">Change location</label>

          <select
            v-if="getLocations.length > 0"
            id="location-context-switch"
            ref="select"
            class="mb-2 w-full rounded-md border border-gray-300 p-2"
            :data-testid="localTestId + '.location-select'"
          >
            <option
              v-for="location in getLocations"
              :key="location.reference"
              :selected="getLocation?.reference === location.reference"
              :data-testid="localTestId + '.location-select.' + location.reference"
              :value="location.reference"
            >
              {{ location.label }}
            </option>
          </select>
        </div>
      </template>

      <template #footer="{ close, localTestId }">
        <footer>
          <div class="flex items-center justify-end gap-2 pt-3">
            <button
              @click="close"
              class="btn btn-medium btn-light"
              :data-testid="localTestId + '.button-cancel'"
            >
              Cancel
            </button>

            <button
              @click="updateLocationContext"
              class="btn btn-primary"
              :data-test-id="localTestId + '.button-submit'"
            >
              Change Location
              <FontAwesomeIcon icon="fa-solid fa-location-dot" />
            </button>
          </div>
        </footer>
      </template>
    </DialogModal>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import DialogModal from '@/components/utilities/DialogModal.vue';
import { type components } from '@/generated/configs';
import { useContextStore } from '@/store/index';
import { type BaseTestable } from '@/utils/baseTestable';

const router = useRouter();
const contextStore = useContextStore();
const props = defineProps<ContextSwitchProps>();
const localTestId = (props.baseTestId ?? 'default') + '.context-switcher';
const showModal = ref(false);

interface ContextSwitchProps extends BaseTestable {
  location?: components['schemas']['LocationSummary'];
  baseTestId: string;
}

const select = ref<HTMLFormElement | undefined>(undefined);

const getLocations = computed(() => {
  return contextStore.getLocations;
});

const getLocation = computed(() => {
  return contextStore.getLocation;
});

const openModal = () => {
  showModal.value = !showModal.value;
};

const closeModal = () => {
  showModal.value = false;
};

function updateLocationContext() {
  closeModal();
  contextStore.setLocation(select.value?.value);
  router.push('/');
}
</script>

<style scoped>
input:not([type='checkbox']),
select,
textarea {
  font-family: var(--vui-font-family);
  font-size: 16px;
  font-weight: var(--vui-font-weight-regular);
}
</style>
