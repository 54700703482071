import createClient, {
  ClientOptions,
  type FetchResponse,
  MaybeOptionalInit,
  Middleware,
} from 'openapi-fetch';
import { HasRequiredKeys, HttpMethod, MediaType } from 'openapi-typescript-helpers';

const getContentOfReadableStream = async (requestBody: ReadableStream<Uint8Array> | null):  Promise<Uint8Array | null> => {

  if (requestBody) {
    let isPending = true;
    let arrayLegnth = 0;
    const unit8Arrays = []
    const reader = requestBody.getReader();
    do {
      const readableResults =  await reader.read();

      if (readableResults.value) {
        arrayLegnth +=  readableResults.value.length
        unit8Arrays.push(readableResults.value)
      }

      isPending = !readableResults.done
    } while (isPending)
    const mergedArray = new Uint8Array(arrayLegnth);
    unit8Arrays.forEach(array=> mergedArray.set(array))
    return mergedArray;

  }
  return null;
}

// openapi-fetch encodes path parameters and this messes up the constructed full url.
// This middleware will create the url as we expect it.
const createFinalUrlMiddleware: Middleware = {
  async onRequest({ request, params, options }) {
    const urlParam = params.path?.url as string | undefined;

    if (urlParam) {
      let finalURL = `${options.baseUrl}${urlParam}`;

      // If there are no existing query parameters, we can use the request.url as is.
      if (finalURL.lastIndexOf('?') === -1) {
        const queryIndex = request.url.lastIndexOf('?');
        if (queryIndex > 0) {
          finalURL += request.url.substring(queryIndex);
        }
      }
      // Else we need to manually add the query parameters to the url
      else {
        for (const key in params.query) {
          if (params.query[key] !== undefined) {
            finalURL += `&${key}=${params.query[key]}`;
          }
        }
      }

      let clonedRequest;

      if (request.body !== null) {
        const requestBody = await getContentOfReadableStream(request.clone().body)
        clonedRequest =  new Request(finalURL, {
          method: request.method,
          headers: request.headers,
          body: requestBody,
          referrer: request.referrer,
          referrerPolicy: request.referrerPolicy,
          mode: request.mode,
          credentials: request.credentials,
          cache: request.cache,
          redirect: request.redirect,
          integrity: request.integrity,
        });
      } else {
        clonedRequest = new Request(finalURL, request);
      }

      return clonedRequest;
    }
  },
};

export default function createUntypedClient<Media extends MediaType = MediaType>(
  clientOptions?: ClientOptions
): Client<Media> {
  const client = createClient<paths<_UnknownMethod>, Media>(clientOptions);
  client.use(createFinalUrlMiddleware);

  function addUrlParam<Method extends HttpMethod>(
    init: Init<paths<UnknownMethod>, Method> | undefined,
    url: string
  ) {
    return {
      ...init,
      params: {
        ...init?.params,
        path: {
          url: url,
        },
      },
    };
  }

  return {
    GET: (url, init) => client.GET('{url}', addUrlParam<'get'>(init, url)),
    PUT: (url, init) => client.PUT('{url}', addUrlParam<'put'>(init, url)),
    POST: (url, init) => client.POST('{url}', addUrlParam<'post'>(init, url)),
    DELETE: (url, init) => client.DELETE('{url}', addUrlParam<'delete'>(init, url)),
    OPTIONS: (url, init) => client.OPTIONS('{url}', addUrlParam<'options'>(init, url)),
    HEAD: (url, init) => client.HEAD('{url}', addUrlParam<'head'>(init, url)),
    PATCH: (url, init) => client.PATCH('{url}', addUrlParam<'patch'>(init, url)),
    TRACE: (url, init) => client.TRACE('{url}', addUrlParam<'trace'>(init, url)),
    use: client.use,
    eject: client.eject,
  };
}

// Helper types

interface paths<M extends UnknownMethod> {
  '{url}': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get: M;
    post: M;
    put: M;
    delete: M;
    options: M;
    head: M;
    patch: M;
    trace: M;
  };
}

// Request and response inforamtion as shown to untyped client users.
export interface UnknownMethod {
  parameters: {
    query?: { [name: string]: any };
    header?: { [name: string]: any };
    cookie?: never;
  };
  requestBody?: {
    content: {
      'application/json': unknown;
    };
  };
  responses: {
    200: {
      content: {
        'application/json': unknown;
      };
    };
  };
}

// Internal request and response information adding the required url path parameter for the internal client
type _UnknownMethod = UnknownMethod & {
  parameters: {
    path: {
      url: string;
    };
  };
};

type Init<
  Paths extends { '{url}': Record<HttpMethod, UnknownMethod> },
  Method extends HttpMethod,
> = MaybeOptionalInit<Paths['{url}'], Method>;

export type ClientMethod<Method extends HttpMethod, Media extends MediaType> = (
  url: string,
  init?: HasRequiredKeys<Init<paths<UnknownMethod>, Method>> extends never
    ? (Init<paths<UnknownMethod>, Method> & { [key: string]: unknown }) | undefined
    : Init<paths<UnknownMethod>, Method> & { [key: string]: unknown }
) => Promise<
  FetchResponse<paths<UnknownMethod>['{url}'][Method], Init<paths<UnknownMethod>, Method>, Media>
>;

export interface Client<Media extends MediaType = MediaType> {
  /** Call a GET endpoint */
  GET: ClientMethod<'get', Media>;
  /** Call a PUT endpoint */
  PUT: ClientMethod<'put', Media>;
  /** Call a POST endpoint */
  POST: ClientMethod<'post', Media>;
  /** Call a DELETE endpoint */
  DELETE: ClientMethod<'delete', Media>;
  /** Call a OPTIONS endpoint */
  OPTIONS: ClientMethod<'options', Media>;
  /** Call a HEAD endpoint */
  HEAD: ClientMethod<'head', Media>;
  /** Call a PATCH endpoint */
  PATCH: ClientMethod<'patch', Media>;
  /** Call a TRACE endpoint */
  TRACE: ClientMethod<'trace', Media>;
  /** Register middleware */
  use(...middleware: Middleware[]): void;
  /** Unregister middleware */
  eject(...middleware: Middleware[]): void;
}
