<template>
  <div :class="dynamicStyling.container" class="flex justify-center border-t-2 px-5 py-4">
    <div class="flex w-[1280px]">
      <div class="flex-shrink-0">
        <FontAwesomeIcon
          :icon="icon ? icon : dynamicStyling.icon"
          class="h-5 w-5"
          :class="dynamicStyling.iconColour"
          aria-hidden="true"
        />
      </div>
      <div class="ml-3">
        <p class="mb-0 text-sm">
          <slot />
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

type Variants = 'danger' | 'warning' | 'info' | 'success' | 'default' | undefined;

const props = defineProps<{
  variant: Variants;
  icon?: string;
}>();

const generateVariant = (variant: Variants) => {
  const container = {
    danger: 'border-red-500 bg-red-50',
    warning: 'border-yellow-500 bg-yellow-50',
    info: 'border-border-info bg-blue-100',
    success: 'border-green-500 bg-green-50',
    default: 'border-gray-500 bg-gray-50',
  };
  return container[variant];
};

const generateIconStyle = (variant: Variants) => {
  const iconColour = {
    danger: 'text-danger-600',
    warning: 'text-yellow-600',
    info: 'text-blue-600',
    success: 'text-green-600',
    default: 'text-gray-600',
  };
  return iconColour[variant];
};

const dynamicStyling = computed(() => {
  if (props.variant) {
    const styling = {
      info: {
        icon: 'fa-regular fa-circle-info',
        container: generateVariant('info'),
        iconColour: generateIconStyle('info'),
      },
      success: {
        icon: 'fa-regular fa-circle-check',
        container: generateVariant('success'),
        iconColour: generateIconStyle('success'),
      },
      danger: {
        icon: 'fa-regular fa-triangle-exclamation',
        container: generateVariant('danger'),
        iconColour: generateIconStyle('danger'),
      },
      warning: {
        icon: 'fa-regular fa-triangle-exclamation',
        container: generateVariant('warning'),
        iconColour: generateIconStyle('warning'),
      },
    };
    return styling[props.variant];
  }
  return {
    icon: 'fa-regular fa-circle-info',
    iconColour: generateIconStyle('default'),
    container: generateVariant('default'),
  };
});
</script>
