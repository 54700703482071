<template>
  <Teleport to="body">
    <dialog ref="dialog" :data-testid="localTestId" v-bind="$attrs">
      <div v-if="hasHeader" class="header">
        <h2>
          <slot name="title" :local-test-id="localTestId" />
        </h2>

        <ButtonComponent
          @click="() => $emit('close')"
          variant="ghost"
          size="sm"
          :data-testid="localTestId + '.button-close'"
        >
          <FontAwesomeIcon icon="fa-regular fa-close" />
        </ButtonComponent>
      </div>

      <slot name="content" :local-test-id="localTestId" />
      <slot name="footer" :close="() => $emit('close')" :local-test-id="localTestId" />
    </dialog>
  </Teleport>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';

import { ButtonComponent } from '../ui/button';

const props = withDefaults(defineProps<DialogModal>(), {
  hasHeader: true,
  baseTestId: String,
});

interface DialogModal {
  hasHeader?: boolean;
  baseTestId?: string;
  showModal: boolean;
}

const localTestId = (props.baseTestId ?? 'default') + '.dialog';
const dialog = ref<Maybe<HTMLDialogElement>>();

watch(
  () => props.showModal,
  (newValue) => {
    const dialogElement = dialog.value;
    if (!dialogElement) return;
    if (newValue) {
      dialogElement.showModal();
    } else {
      dialogElement.close();
    }
  }
);
</script>
<style lang="scss" scoped>
div.header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
}
</style>
