<template>
  <nav>
    <div class="sm:px-3 lg:px-4">
      <div class="flex h-12 items-center">
        <div class="mr-auto flex flex-1">
          <div class="flex flex-shrink-0"><ContextSwitcher :base-test-id="baseTestId" /></div>
        </div>
        <div class="flex flex-1 justify-center">
          <NavFavoriteMenu />
        </div>
        <div class="flex flex-1 items-center justify-end gap-3">
          <NavUserMenu />
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import NavFavoriteMenu from '@/components/navbar/NavFavoriteMenu.vue';
import NavUserMenu from '@/components/navbar/NavUserMenu.vue';
import ContextSwitcher from '@/components/utilities/ContextSwitcher.vue';

const baseTestId = 'nav-bar';
</script>
